import { connect } from 'react-redux'
import { addParticipans_failure, addParticipans_request } from '../actions/congress.action'
import Registration from '../components/Registration'

const mapStateToProps = state => ({
    addParticipansMsg: state.memberReducer.addParticipansMsg
})

const mapDispatchToProps = dispatch => ({
    addParticipans: (details) => dispatch(addParticipans_request(details))
})

const RegistrationCnt = connect (
    mapStateToProps,
    mapDispatchToProps
)(Registration)

export default RegistrationCnt