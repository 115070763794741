import React from 'react'
import { withRouter } from 'react-router-dom'

class CongressDetails extends React.Component {
    render() {
        return( 
            <div className="container">
                <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F", paddingBottom:'10px'}}>NAJAVA KONGRESA</p>
                        <p style={{fontStyle: 'italic'}}>Sa velikim zadovoljstvom vas pozivamo da budete dio <b>V Kongresa stomatologa Crne Gore sa međunarodim učešćem, u organizaciji Stomatološke komore Crne Gore, koji će se održati od 20. do 23. marta 2025. godine u prestižnom hotelu „Regent“ Porto Montenegro u Tivtu.</b></p>
                        <p style={{fontStyle: 'italic'}}>Pod sloganom <b><i>„Savremeni pristupi i trendovi u stomatologiji“</i></b>, ovaj kongres predstavlja centralni naučni i stručni događaj u regionu, namjijenjen stomatolozima, studentima stomatologije, tehničarima kao i partnerima iz industrije. Cilj ovog značajnog skupa je promovisanje najnovijih saznanja i praksi iz oblasti stomatologije, kroz mulidisciplinarni pristup, razmjenu iskustava i inspiraciju za dalji razvoj struke. </p>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Kongres će obuhvatiti bogat i raznovrstan program:</p>
                        <ul style={{}}>
                            <li><b>Predavanja renomiranih domaćih i međunarodih predavača</b>, , koji će podijeliti svoja iskustva i rezultate najnovijih istraživanja iz različitih oblasti stomatologije, uključujući ortodonciju, oralnu hirurgiju, implantologiju, parodontologiju i mnoge druge. </li>
                            <li><b>Interaktvine radionice</b>, gdje će učesnici imati priliku da unaprijede svoje praktične vještine i upoznaju se sa najsavremenijim tehnikama i tehnologijama.</li>
                            <li><b>Diskusije</b> na kojima će se razmatrati aktuelni izazovi i trendovi u stomatologiji.</li>
                            <li><b>Izložbeni prostor</b> sa najnovijom stomatološkom opremom, materijalima i inovacijama, što pruža mogućnos direktne komunikacije sa vodećim kompanijama u industriji.</li>
                        </ul>
                        <p style={{}}>Pozivamo vas da postanete dio ovog značajnog događaja koji će okupiti vrhunske stručnjake iz oblasti stomatologije.</p>
                        <p style={{}}>V Kongres stomatologa Crne Gore sa međunarodim učešćem predstavlja jedinstvenu priliku za edukaciju, profesionlni razvoj i uspostavljanje novih kontakata. </p>
                        <p style={{}}>Pridružite nam se u Hotelu „Regent“ Porto Montenegro, da uz spoj vrhunske usluge i predivnog pogleda na Jadransko more zajedno otkrijemo nove dimenzije stomatološke prakse!</p>
                        <p style={{}}>Vidimo se na V Kongresu, gdje inovacije i znanje stvaraju temelje za bolju budućnost stomatologije!</p>
                    </div>

                    <div className="col-md-10 col-md-offset-1" style={{paddingTop:'20px'}}>
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F", paddingBottom:'10px'}}>KOTIZACIJE</p>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Visina kotizacije za učestvovanje:</p>
                        <ul style={{}}>
                            <li><b>Rana kotizacija (do 01.03.2025. godine):</b>
                                <ul>
                                    <li>Za članove Komore: 150€</li>
                                    <li>Za ostale učesnike: 200€</li>
                                </ul>
                            </li>
                            <li><b>Kasna kotizacija (do 15.03.2025. godine):</b>
                                <ul>
                                    <li>Za članove Komore: 200€</li>
                                    <li>Za ostale učesnike: 250€</li>
                                </ul>
                            </li>
                            <li><b>Kotizacija za tehničare: 100€</b></li>
                            <li><b>Cijena za Gala večeru - uplaćuje se sa kotizacijom (21.03.2025. godine): 100€</b></li>
                        </ul>
                    </div>

                    <div className="col-md-10 col-md-offset-1" style={{paddingTop:'20px'}}>
                        <ul style={{}}>
                            <li style={{color:"#EF662F"}}><b><i>NAPOMENA:</i> Kotizacija uključuje ručak i kafe pauze u petak i subotu (21. i 22. mart 2025. godine).</b>
                            </li>
                            {/* <li><b><i>Za studente stomatologije učešće je besplatno uz potvrdu studiranja</i></b></li> */}
                        </ul>
                    </div>

                    <div className="col-md-10 col-md-offset-1" style={{paddingTop:'20px'}}>
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F", paddingBottom:'10px'}}>BODOVANJE</p>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Učesnici će ostvariti bodove na sljedeći način:</p>
                        <ul style={{}}>
                            <li><b>Pasivno učešće: 12 bodova</b></li>
                            <li><b>Aktivno učešće (prvi autor): 20 bodova</b></li>
                            <li><b>Drugi autor: 15 bodova</b></li>
                            <li><b>Predavač: 25 bodova</b></li>
                        </ul>
                    </div>

                    <div className="col-md-10 col-md-offset-1" style={{paddingTop:'20px'}}>
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F", paddingBottom:'10px'}}>Posebni uslovi smještaja:</p>
                        <ul style={{}}>
                            <li><b>Za učesnike V Kongresa obezbijeđena je posebna cijena noćenja u hotelu „Regent“ Porto Montenegro: 200€ + 10% PDV-a po sobi</b></li>
                        </ul>
                    </div>


                </div>
            </div>
        )
    }
}

export default withRouter(CongressDetails)